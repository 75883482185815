'use strict';

import numeral from 'numeral';
import de from 'numeral/locales/de';
numeral.locale('de');

import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';

import HandicapCalculator from './HandicapCalculator/HandicapCalculator';

import '../scss/main.scss';

const handicapCalculatorDiv = document.getElementById('handicap-calculator');
if (handicapCalculatorDiv) {
    const render = () => {
        const dataset = handicapCalculatorDiv.dataset;

        const clubs = dataset.clubs ? JSON.parse(dataset.clubs) : [];
        const club = dataset.club ? JSON.parse(dataset.club) : null;
        const options = dataset.options ? JSON.parse(dataset.options) : {};

        ReactDOM.render(
            <AppContainer>
                <HandicapCalculator club={club} clubs={clubs} options={options} />
            </AppContainer>,
            handicapCalculatorDiv
        );
    };

    render();

    // Hot Module Replacement API
    if (module.hot) {
        // module.hot.accept();
        module.hot.accept('./HandicapCalculator/HandicapCalculator', () => {
            render();
        });
    }
}
