'use strict';

import numeral from 'numeral';

export function formatHandicap(value, digits = 1) {
    if (value <= -36) {
        return numeral(Math.abs(value)).format('0');
    } else {
        return (value > 0.01 ? '+' : '') + numeral(Math.abs(value)).format(digits === 1 ? '0.0' : '0');
    }
}
