import React from 'react';
import ReactDOM from 'react-dom';

export default class ClubDataModal extends React.Component {
    state = {
        isOpen: false,
    };
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });
    render() {
        return (
            <React.Fragment>
                <ModalTrigger openModal={this.openModal} />
                {this.state.isOpen && <ModalContent closeModal={this.closeModal} />}
            </React.Fragment>
        );
    }
}

const ModalTrigger = ({ openModal }) => (
    <a href="#" className="ml-2" onClick={openModal}>
        Platzdaten nicht aktuell ?
    </a>
);
const ModalContent = ({ closeModal }) =>
    ReactDOM.createPortal(
        <div className="club-data-modal modal">
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Sende uns die aktuellen Platzdaten</h5>
                        <span onClick={closeModal} className="close cursor-pointer">
                            &times;
                        </span>
                    </div>
                    <div className="modal-body">
                        <textarea className="form-control" name="" id="" cols="30" rows="10"></textarea>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-primary">Hochladen</button>
                    </div>
                </div>
            </div>
        </div>,
        document.body
    );
