'use strict';

import React from 'react';
import classNames from 'classnames';
import numeral from 'numeral';
import Select from 'react-select';
import request from 'superagent';
import Calculation from './Calculation';
import InputHandicap from './InputHandicap';
import { formatHandicap } from './Format';
import Choice from './Widget/Choice';

import ResponsiveResultTable from './View/ResponsiveResultTable';
import ResultStats from './View/ResultStats';
import ClubDataModal from './ClubDataModal';

const ENTER_MODE__STROKES = 'strokes';
const ENTER_MODE__OVER_PARS = 'over-pars';

function formatUrl(url) {
    if (location.href.indexOf('app_dev.php') > -1) {
        return '/app_dev.php' + url;
    }
    return url;
}

function preventEvent(e, func) {
    e.preventDefault();
    e.stopPropagation();
    return false;
}

const DEFAULT_OPTIONS = {
    text: {
        headlineLocation: 'Wo hast Du gespielt?',
        headlineHandicap: 'Welches Handicap hast Du?',
        headlineResults: 'Wie hast Du gespielt?',
    },
};

export default class HandicapCalculator extends React.Component {
    constructor(props) {
        super(props);

        const overPars = {};
        for (var i = 1; i <= 18; i++) {
            overPars[i] = 0;
        }

        this.state = {
            stammvorgabe: (localStorage && parseFloat(localStorage.getItem('hcp'))) || -18,
            club: null,
            activeClub: null,
            activeCourse: -1,
            activeTeeBox: -1,
            overPars,
            front: true,
            back: true,
            enterMode: (localStorage && localStorage.getItem('enterMode')) || ENTER_MODE__OVER_PARS,
            ...this.getClubStateData(props.club),
            clubsNearby: [],
        };

        this.calculator = new Calculation();
    }

    getOptions() {
        const options = this.props.options || {};
        return {
            text: {
                ...DEFAULT_OPTIONS.text,
                ...options.text,
            },
        };
    }

    setFront() {
        let { front, back } = this.state;
        if (front && back) {
            front = true;
            back = false;
        } else {
            front = true;
            back = true;
        }

        this.setState({ front, back });
    }

    setBack() {
        let { front, back } = this.state;
        if (front && back) {
            front = false;
            back = true;
        } else {
            front = true;
            back = true;
        }

        this.setState({ front, back });
    }

    setEnterMode(enterMode) {
        this.setState({ enterMode });
        localStorage && localStorage.setItem('enterMode', enterMode);
    }

    setClubsNearBy(show) {
        if (show) {
            this.setState({ clubsNearby: [] });
        } else {
            navigator.geolocation.getCurrentPosition(location => {
                const { latitude, longitude } = location.coords;
                request
                    .get(formatUrl(`/clubs/nearby/${latitude}/${longitude}.json`))
                    .type('json')
                    .end((error, response) => {
                        this.setState({ clubsNearby: response.body });
                    });
            });
        }
    }

    componentDidMount() {
        this.setOverParToHcp();
    }

    getClub() {
        return this.state.club;
    }

    getCourse() {
        return this.getClub().courses[this.state.activeCourse];
    }

    getTeeBox() {
        return this.getCourse().tee_boxes[this.state.activeTeeBox];
    }

    getHoles() {
        const teeBox = this.getTeeBox();

        const holes = [];
        for (var i = 1; i <= 18; i++) {
            holes.push({
                number: i,
                length: teeBox['hole' + i + '_length'],
                hcp: teeBox['hole' + i + '_hcp'],
                par: teeBox['hole' + i + '_par'],
            });
        }

        return holes;
    }

    getSlope() {
        return this.getTeeBox().slope || 0;
    }

    getCr() {
        return this.getTeeBox().cr || 0;
    }

    getPar() {
        return this.getTeeBox().par || 0;
    }

    isCalculatable() {
        if (this.state.clubsNearby.length > 0) {
            return false;
        }

        if (-1 === this.state.activeTeeBox || null === this.state.stammvorgabe) {
            return false;
        }
        return true;
    }

    setHandicap(stammvorgabe) {
        this.setState({ stammvorgabe }, () => this.setOverParToHcp());
        localStorage && localStorage.setItem('hcp', stammvorgabe);
    }

    setOverParToHcp() {
        if (!this.isCalculatable()) {
            return;
        }

        const spielvorgabe = this.calculator.getSpielvorgabe(this.state.stammvorgabe, this.getSlope(), this.getCr(), this.getPar());
        const overPars = this.calculator.getVorgaben(this.getHoles(), spielvorgabe);
        this.setState({ overPars });
    }

    setOverPar(hole, value) {
        var { overPars } = this.state;
        overPars[hole] = value;
        this.setState({ overPars });
    }

    getClubStateData(club) {
        if (!club) {
            return {};
        }

        // active course first course
        const activeCourse = club.courses.length > 0 ? 0 : -1;
        // active teebox first teebox if exists
        const activeTeeBox = activeCourse > -1 && club.courses[0].tee_boxes.length > 0 ? 0 : -1;
        // check if current teebox is 9 hole
        const is9 = activeTeeBox > -1 && club.courses[0].tee_boxes[0].is9;

        return {
            activeClub: club.id,
            club: club,
            activeCourse,
            activeTeeBox,
            front: true,
            back: !is9,
            clubsNearby: [],
        };
    }

    selectClub(value) {
        if (!value) {
            return;
        }

        request
            .get(formatUrl('/club/' + value + '.json'))
            .type('json')
            .end((error, response) => {
                const club = response.body;
                this.setState(this.getClubStateData(club), () => this.setOverParToHcp());

                const page = formatUrl('/' + club.slug + '/');
                window.history.pushState('page2', 'Title', page);

                ga && ga('send', 'pageview', page);
            });
    }

    render() {
        const clubs = (this.props.clubs || []).map(club => {
            return {
                value: club.id,
                label: club.name,
            };
        });
        const getCourses = () =>
            this.getClub().courses.map((course, i) => {
                return {
                    value: i,
                    label: course.name,
                };
            });

        const { stammvorgabe, overPars, clubsNearby } = this.state;
        const values = this.isCalculatable()
            ? this.calculator.calculate(
                  stammvorgabe,
                  this.getHoles(),
                  this.getSlope(),
                  this.getCr(),
                  this.getPar(),
                  overPars,
                  this.state.front,
                  this.state.back
              )
            : {};

        return (
            <form>
                <div className="card section-header-opacity mb-2">
                    <div className="bg-section-header card-header d-flex align-items-center justify-content-between">
                        <h2 className="lead m-0 text-white">{this.getOptions().text.headlineLocation}</h2>
                        {clubs.length > 0 && navigator.geolocation && (
                            <div className="btn-group pull-right">
                                <a
                                    className={classNames('btn btn-secondary btn-sm', { active: clubsNearby.length > 0 })}
                                    onClick={e => preventEvent(e, this.setClubsNearBy(clubsNearby.length))}>
                                    <i className="fa fa-map-marker mr-1"></i>Clubs in der Nähe
                                </a>
                            </div>
                        )}
                    </div>
                    {clubs.length > 0 && clubsNearby.length === 0 && (
                        <div className="container-fluid">
                            <Select
                                options={clubs}
                                value={this.state.activeClub}
                                onChange={club => this.selectClub(club.value)}
                                placeholder="Wähle Deinen Club!"
                                nullable={false}
                                className="mt-2 mb-2 react-select"
                            />
                        </div>
                    )}

                    {clubsNearby.length > 0 && (
                        <table className="table table-striped">
                            <tbody>
                                {clubsNearby
                                    .filter(club => club.distance > 0)
                                    .map(club => (
                                        <tr key={club.id}>
                                            <td>{club.name}</td>
                                            <td>{numeral(parseFloat(club.distance)).format('0.0')} km</td>
                                            <td className="text-sm-right">
                                                <button className="btn btn-primary btn-sm" onClick={e => preventEvent(e, this.selectClub(club.id))}>
                                                    <i className="fa fa-chevron-right"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    )}

                    {this.isCalculatable() && (
                        <div className="container-fluid mt-2">
                            {getCourses().length > 1 && (
                                <div className="form-group">
                                    <label>Kurs</label>
                                    <Choice
                                        options={getCourses()}
                                        value={this.state.activeCourse}
                                        onChange={course => this.setState({ activeCourse: course.value })}
                                    />
                                </div>
                            )}
                            <div className="row mb-3">
                                <div className="col-sm-6 d-flex flex-column">
                                    <label>Abschlag</label>
                                    <Choice
                                        options={this.getCourse().tee_boxes.map((teeBox, i) => ({
                                            value: i,
                                            label: teeBox.name,
                                        }))}
                                        value={this.state.activeTeeBox}
                                        onChange={teeBox => this.setState({ activeTeeBox: teeBox.value })}
                                    />
                                </div>
                                <div className="col-sm-6 mt-3 mt-sm-0 d-flex flex-column">
                                    <label>Wo wird gespielt?</label>
                                    <div className="btn-group">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <button
                                                className={classNames('btn btn-secondary', { active: this.state.front })}
                                                onClick={e => preventEvent(e, this.setFront())}>
                                                Front Nine 1.-9
                                            </button>
                                            <button
                                                className={classNames('btn btn-secondary ml-2', { active: this.state.back })}
                                                onClick={e => preventEvent(e, this.setBack())}>
                                                Back Nine 10.-18
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="card section-header-opacity">
                    <div className="bg-section-header card-header">
                        <h2 className="lead m-0 text-white">{this.getOptions().text.headlineHandicap}</h2>
                    </div>
                    <div className="container-fluid mt-2 mb-2">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group mb-0" style={{ width: 200 }}>
                                    <InputHandicap setValue={value => this.setHandicap(value)} value={stammvorgabe} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.isCalculatable() && (
                    <div className="card section-header-opacity mb-2 mt-2">
                        <div className="bg-section-header card-header d-flex align-items-start justify-content-between flex-column flex-sm-row align-items-sm-center">
                            <h2 className="lead m-0 text-white">{this.getOptions().text.headlineResults}</h2>
                            <div className="btn-group mt-3 mt-sm-0">
                                <button
                                    className={classNames('btn btn-secondary btn-sm', { active: this.state.enterMode === ENTER_MODE__STROKES })}
                                    onClick={e => preventEvent(e, this.setEnterMode(ENTER_MODE__STROKES))}>
                                    <i className="fa fa-edit" /> Schläge
                                </button>
                                <button
                                    className={classNames('btn btn-secondary btn-sm ml-1', { active: this.state.enterMode === ENTER_MODE__OVER_PARS })}
                                    onClick={e => preventEvent(e, this.setEnterMode(ENTER_MODE__OVER_PARS))}>
                                    <i className="fa fa-edit" /> Schläge über PAR
                                </button>
                            </div>
                        </div>
                        <div className="card-block result-table-wrapper">
                            <ResponsiveResultTable
                                front={this.state.front}
                                back={this.state.back}
                                holes={values.holes}
                                setOverPar={(hole, value) => this.setOverPar(hole, value)}
                                enterMode={this.state.enterMode}
                            />
                            {this.isCalculatable() && (
                                <div className="text-center mb-2">
                                    <small>
                                        CR {numeral(this.getCr()).format('0.0')} - Slope {this.getSlope()} - PAR {this.getPar()} - Spielvorgabe{' '}
                                        {formatHandicap(values.spielvorgabe, 0)}
                                        <ClubDataModal />
                                    </small>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {this.isCalculatable() && <ResultStats values={values} />}
            </form>
        );
    }
}
