'use strict';

import React from 'react';
import classNames from 'classnames';
import numeral from 'numeral';

import { formatHandicap } from '../Format';

export default class ResultStats extends React.Component {
    render() {
        const { values } = this.props;

        return (
            <div className="card section-header-opacity card-block result-container">
                <div className="container-fluid">
                    <div className="row justify-content-sm-between justify-content-around calculator-result my-2">
                        <div className="col result-score">
                            <div className="value">{values.bruttoSum}</div>
                            <div className="description">
                                Stableford <br className="hidden-sm-up" /> Brutto
                            </div>
                        </div>
                        <div className="col result-score">
                            <div
                                className={classNames('value', {
                                    'text-success': values.didImprove,
                                    'text-danger': values.didDegrade,
                                })}>
                                {values.nettoSum}
                            </div>
                            <div className="description">
                                Stableford <br className="hidden-sm-up" /> Netto
                            </div>
                        </div>
                        <div className="col result-score">
                            <div className="value">{numeral(Math.abs(values.strokesSum)).format('0')}</div>
                            <div className="description">Schläge</div>
                        </div>
                        <div className="col result-score">
                            <div className="value">{0 === values.overParSum ? 'EVEN' : numeral(Math.abs(values.overParSum)).format('0')}</div>
                            <div className="description">
                                {values.overParSum > 0 && 'Über'} {values.overParSum < 0 && 'Unter'} PAR
                            </div>
                        </div>
                        <div className="col result-score">
                            <div
                                className={classNames('value', {
                                    'text-success': values.didImprove,
                                    'text-danger': values.didDegrade,
                                })}>
                                {formatHandicap(values.newHandicap)}
                            </div>
                            <div className="description">
                                Neues <br className="hidden-sm-up" /> Handicap
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
