'use strict';

import React from 'react';
import Select from 'react-select';
import classNames from 'classnames';

function preventEvent(e, func) {
    e.preventDefault();
    e.stopPropagation();
    return false;
}

export default class Choice extends React.Component {
    render() {
        const { options, value, onChange } = this.props;

        if (options.length > 4) {
            return <Select options={options} value={value} onChange={onChange} nullable={false} style={{width: '100%'}} />;
        }

        return (
            <div className="d-flex align-items-center justify-content-between">
                <div className="btn-group">
                    {options.map(option => {
                        let label = option.label;

                        const match = label.match(/^(.*)\s+\((.*)\)/);
                        if (match) {
                            const [, name, color] = match;
                            switch (color) {
                                case 'rot':
                                    label = <React.Fragment><span className="mr-2 square red" /> {name}</React.Fragment>;
                                    break;
                                case 'gelb':
                                    label = <React.Fragment><span className="mr-2 square yellow" /> {name}</React.Fragment>;
                                    break;
                                case 'grün':
                                    label = <React.Fragment><span className="mr-2 square green" /> {name}</React.Fragment>;
                                    break;
                                case 'weiß':
                                    label = <React.Fragment><span className="mr-2 square white" /> {name}</React.Fragment>;
                                    break;
                                case 'blau':
                                    label = <React.Fragment><span className="mr-2 square blue" /> {name}</React.Fragment>;
                                    break;
                                default:
                                    label = <React.Fragment>{name}</React.Fragment>;
                            }
                        }

                        return (
                            <button
                                key={option.value}
                                className={classNames('btn btn-secondary mr-2 d-flex align-items-center justify-contenetn-around', {
                                    active: value === option.value,
                                })}
                                onClick={e => preventEvent(e, onChange(option))}>
                                {label}
                            </button>
                        );
                    })}
                </div>
            </div>
        );
    }
}
