'use strict';

import React from 'react';
import ResultTable from './ResultTable';

export default class ResponsiveResultTable extends React.Component {
    /**
     * @param front
     * @param back
     */
    getHoles(front, back) {
        const { holes } = this.props;

        return holes.filter(hole => (hole.number <= 9 && front) || (hole.number > 9 && back));
    }

    /**
     * @param front
     * @param back
     * @returns {XML}
     */
    getResultTable(front, back) {
        const { setOverPar, enterMode } = this.props;

        return <ResultTable holes={this.getHoles(front, back)} setOverPar={(hole, value) => setOverPar(hole, value)} enterMode={enterMode} />;
    }

    /**
     * @returns {XML}
     */
    render() {
        const { front, back } = this.props;

        return (
            <div>
                <div className="d-none d-xl-block">
                    <div className="container-fluid">
                        <div className="row">
                            {front && <div className="col-md-6 p-1">{this.getResultTable(true, false)}</div>}
                            {back && <div className="col-md-6 p-1">{this.getResultTable(false, true)}</div>}
                        </div>
                    </div>
                </div>
                <div className="d-lg-block d-xl-none">{this.getResultTable(front, back)}</div>
            </div>
        );
    }
}
