'use strict';

export default class Calculation {
    /**
     *  1	   0 - 4.4  35-36	0.1	0.1
     *  2	 4.5 - 11.4	34-36	0.1	0.2
     *  3	11.5 - 18.4	33-36	0.1	0.3
     *  4	18.5 - 26.4	32-36	0.1 0.4
     *  5	26.5 - 36.0	31-36	0.2	0.5
     *  6	36	 	Keine Verschlechterung bei Clubvorgaben	1.0
     *
     * @param stammvorgabe
     * @param nettoStableford
     *
     * @returns {*}
     */
    calculateHandicap(stammvorgabe, nettoStableford) {
        var newHandicap = stammvorgabe;
        if (nettoStableford >= 36) {
            const steps = nettoStableford - 36;

            for (var i = 1; i <= steps; i++) {
                if (newHandicap < -36) {
                    newHandicap += 1;
                } else if (newHandicap < -26.4) {
                    newHandicap += 0.5;
                } else if (newHandicap < -18.4) {
                    newHandicap += 0.4;
                } else if (newHandicap < -11.4) {
                    newHandicap += 0.3;
                } else if (newHandicap < -4.4) {
                    newHandicap += 0.2;
                } else {
                    newHandicap += 0.1;
                }
            }
        } else {
            if (newHandicap > -26.5) {
                newHandicap -= 0.1;
            } else if (newHandicap > -36) {
                newHandicap -= 0.2;
            }
        }

        return newHandicap;
    }

    /**
     *
     * @param stammvorgabe
     * @param slope
     * @param cr
     * @param par
     * @returns {number}
     */
    getSpielvorgabe(stammvorgabe, slope, cr, par) {
        let spielvorgabe;

        if (stammvorgabe > -36) {
            spielvorgabe = Math.round(stammvorgabe * (slope / 113) - cr + par);
        } else {
            spielvorgabe = stammvorgabe + Math.round(-36 * (slope / 113) - cr + par) + 36;
        }

        return spielvorgabe;
    }

    /**
     *
     * @param overPar
     * @param vorgabe
     * @returns {number}
     */
    getStableford(overPar, vorgabe = 0) {
        return Math.max(2 + vorgabe - overPar, 0);
    }

    /**
     *
     * @param holes
     * @param spielvorgabe
     * @returns object
     */
    getVorgaben(holes, spielvorgabe) {
        const vorgaben = {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0,
            12: 0,
            13: 0,
            14: 0,
            15: 0,
            16: 0,
            17: 0,
            18: 0,
        };

        // better than par (positive handicap)
        const holesByDifficultyDesc = holes.sort((hole1, hole2) => hole2.hcp - hole1.hcp);
        for (var i = 1; i <= spielvorgabe; i++) {
            const number = holesByDifficultyDesc[(i - 1) % holesByDifficultyDesc.length].number;
            vorgaben[number]--;
        }

        // worse than par (negative handicap)
        const holesByDifficultyAsc = holes.sort((hole1, hole2) => hole1.hcp - hole2.hcp);
        for (var i = spielvorgabe, j = 1; i < 0; i++, j++) {
            const number = holesByDifficultyAsc[(j - 1) % holesByDifficultyAsc.length].number;
            vorgaben[number]++;
        }

        return vorgaben;
    }

    /**
     * Does all the calculation
     *
     * @param stammvorgabe
     * @param holes
     * @param slope
     * @param cr
     * @param par
     * @param overPars
     * @param front9
     * @param back9
     * @returns {{spielvorgabe: number, newHandicap: *, vorgaben: Object, nettoSum: number, bruttoSum: number, overParSum: number, parFront: number, parBack: number, nettoFront: number, nettoBack: number, bruttoFront: number, bruttoBack: number, lengthFront: number, lengthBack: number}}
     */
    calculate(stammvorgabe, holes, slope, cr, par, overPars, front9, back9) {
        const spielvorgabe = this.getSpielvorgabe(stammvorgabe, slope, cr, par);
        const vorgaben = this.getVorgaben(holes, spielvorgabe);

        let nettoSum = 0,
            bruttoSum = 0,
            overParSum = 0,
            strokesSum = 0;

        holes.forEach(hole => {
            if ((hole.number < 10 && front9) || (hole.number >= 10 && back9)) {
                hole.overPar = overPars[hole.number];
                hole.vorgabe = vorgaben[hole.number];
                hole.strokes = hole.par + overPars[hole.number];
                hole.netto = this.getStableford(overPars[hole.number], vorgaben[hole.number]);
                hole.brutto = this.getStableford(overPars[hole.number]);
            } else {
                hole.vorgabe = 0;
                hole.overPar = 0;
                hole.netto = 2;
                hole.brutto = 0;
                hole.strokes = 0;
            }

            nettoSum += hole.netto;
            bruttoSum += hole.brutto;
            overParSum += hole.overPar;
            strokesSum += hole.strokes;
        });

        const newHandicap = this.calculateHandicap(stammvorgabe, nettoSum);

        // sort holes by number
        holes.sort((hole1, hole2) => hole1.number - hole2.number);

        // when only 9 holes are played, we need to subtract the netto
        // point of the additional 9 holes which are always 2
        if ((front9 && !back9) || (!front9 && back9)) {
            nettoSum -= 18;
        }

        return {
            stammvorgabe,
            spielvorgabe,
            newHandicap,
            holes,
            vorgaben,
            nettoSum,
            bruttoSum,
            overParSum,
            strokesSum,
            didImprove: newHandicap > stammvorgabe,
            didDegrade: newHandicap < stammvorgabe,
        };
    }
}
