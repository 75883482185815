'use strict';

import React from 'react';
import numeral from 'numeral';
import classNames from 'classnames';

import { formatHandicap } from './Format';

const HCP_MAX = -54;
const HCP_MIN = 10;

export default class InputHandicap extends React.Component {
    inc(e) {
        const { value } = this.props;

        if (value < -36.1) {
            // increment by 1
            this.setValueInState(value + 1);
        } else {
            // increment by .1
            this.setValueInState(value + 0.1);
        }

        e.preventDefault();
        return false;
    }

    dec(e) {
        const { value } = this.props;

        if (value >= -36) {
            // increment by 1
            this.setValueInState(value - 0.1);
        } else {
            // increment by .1
            this.setValueInState(value - 1);
        }

        e.preventDefault();
        return false;
    }

    setValue(value, force = false) {
        let parsed = parseFloat(value.replace(/,/, '.'));
        if (isNaN(parsed)) {
            parsed = 18;
        }

        // explicitly set positive value when prefixed with '+', negative value otherwise
        if ('+' !== value.substr(0, 1)) {
            parsed *= -1;
        }

        clearTimeout(this.timeout);

        // set handicap directly when the entered value is complete
        if (force || value === formatHandicap(parsed)) {
            this.setValueInState(parsed);
            return;
        }

        // otherwise wait for 2 seconds to force input
        if (0 !== value.trim().length) {
            this.timeout = setTimeout(() => {
                this.setValueInState(parsed);
            }, 2000);
        }
    }

    setValueInState(value) {
        // ensure min and max values
        value = Math.max(value, HCP_MAX);
        value = Math.min(value, HCP_MIN);
        // set
        this.input.value = formatHandicap(value);
        this.props.setValue(value);
    }

    render() {
        const { value } = this.props;

        return (
            <div className="input-group">
                <button className="input-group-addon btn btn-secondary" onClick={e => this.inc(e)}>
                    <span className="fa fa-lg fa-minus" />
                </button>
                <input
                    type="search"
                    className="form-control"
                    style={{ textAlign: 'center' }}
                    ref={input => (this.input = input)}
                    defaultValue={formatHandicap(value)}
                    onChange={e => this.setValue(e.target.value)}
                    onBlur={e => this.setValue(e.target.value, true)}
                />
                <button className="input-group-addon btn btn-secondary" onClick={e => this.dec(e)}>
                    <span className="fa fa-lg fa-plus" />
                </button>
            </div>
        );
    }
}
