'use strict';

import React from 'react';
import NumericInput from 'react-numeric-input';

export default class ResultTable extends React.Component {
    render() {
        const { holes, setOverPar, enterMode } = this.props;

        let lengthSum = 0,
            parSum = 0,
            overParSum = 0,
            strokesSum = 0,
            nettoSum = 0,
            bruttoSum = 0;
        return (
            <table className="table table-striped scoring-table mb-0">
                <thead>
                    <tr>
                        <th className="th-hole" />
                        <th className="rotate hcp">
                            <div>HCP</div>
                        </th>
                        <th className="rotate length">
                            <div>Länge</div>
                        </th>
                        <th className="rotate">
                            <div>PAR</div>
                        </th>
                        <th className="rotate vorgabe">
                            <div>Vorgabe</div>
                        </th>
                        <th className={`rotate ${enterMode === `strokes` ? `strokes` : ``}`}>
                            <div>Schläge</div>
                        </th>
                        <th className={`rotate ${enterMode === `over-pars` ? `over-pars` : ``}`}>
                            <div>über PAR</div>
                        </th>
                        <th className="rotate netto">
                            <div>Netto</div>
                        </th>
                        <th className="rotate brutto">
                            <div>Brutto</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {holes.map(hole => {
                        lengthSum += hole.length;
                        parSum += hole.par;
                        overParSum += hole.overPar;
                        strokesSum += hole.strokes;
                        nettoSum += hole.netto;
                        bruttoSum += hole.brutto;

                        return <HoleRow key={hole.number} {...hole} setOverPar={value => setOverPar(hole.number, value)} enterMode={enterMode} />;
                    })}
                </tbody>
                <thead>
                    <tr>
                        <th />
                        <th />
                        <th>{lengthSum && <span>{lengthSum}m</span>}</th>
                        <th>{parSum}</th>
                        <th />
                        <th>{strokesSum}</th>
                        <th>{overParSum}</th>
                        <th>{nettoSum}</th>
                        <th>{bruttoSum}</th>
                    </tr>
                </thead>
            </table>
        );
    }
}

class HoleRow extends React.Component {
    render() {
        const { number, length, hcp, par, netto, brutto, vorgabe, overPar, setOverPar, strokes, enterMode } = this.props;

        let striche = '';
        for (let i = 0; i < Math.abs(vorgabe); i++) {
            striche += '/';
        }

        return (
            <tr>
                <td className="hole">{number}</td>
                <td>{hcp}</td>
                <td>{length && <span>{length}m</span>}</td>
                <td className="td-par" style={{ width: 10 }}>
                    {par}
                </td>
                <td style={{ textAlign: vorgabe < 0 ? 'left' : 'right' }}>{striche}</td>
                <td style={{ width: enterMode === 'strokes' ? 120 : '' }}>
                    {enterMode === 'strokes' ? (
                        <NumericInput className="form-control schlaege-input" onChange={value => setOverPar(value - par)} value={strokes} min={1} max={20} />
                    ) : (
                        strokes
                    )}
                </td>
                <td style={{ width: enterMode === 'over-pars' ? 120 : '' }}>
                    {enterMode === 'over-pars' ? (
                        <NumericInput className="form-control schlaege-input" onChange={value => setOverPar(value)} value={overPar} min={-4} max={20} />
                    ) : (
                        overPar
                    )}
                </td>
                <td>{netto}</td>
                <td>{brutto}</td>
            </tr>
        );
    }
}
